import React from 'react'

function Footer() {
  return (
<footer className="footer-wrapper">
  <div className="container">
    <img src="./img/gamebiz 1.png" />
    <div className="icon-wrapper  mt-3">
      <img src="./img/Social Network.png" />
      <img src="./img/Social Network (1).png" />
    </div>
  </div>
</footer>

  )
}

export default Footer